import { ComponentsOverrides } from '@mui/material';
import { COLOURS } from 'ontariohealth-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiAppBar'] = {
  root: {
    borderBottom: `3px solid ${COLOURS.AccentYellow}`,
  },
};

export const MuiAppBar = {
  styleOverrides,
};
