import { Stack, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PATH_SEARCH } from '../../content/constants';
import { ReactComponent as Check } from '../../assets/checkmark.svg';

export default function BarcodeSuccess(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack padding={5}>
      <Typography variant="h4" className="search__header" marginBottom={4}>
        {t(`barcode.registered`)}
      </Typography>

      <Typography>{t('barcode.registered_subheader')}</Typography>

      <Stack alignItems="center" margin={10} padding={10}>
        <Check />
      </Stack>

      <Stack alignItems="baseline" marginBottom={5} marginTop={5}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(PATH_SEARCH)}
        >
          {t('barcode.nearest')}
        </Button>
      </Stack>
    </Stack>
  );
}
