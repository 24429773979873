import { COLOURS } from './colours';
import { spacing } from './constants';
const styleOverrides = {
    root: {
        '.MuiFilledInput-root': {
            marginRight: spacing * 3,
            fontSize: '14px',
            lineHeight: '16px',
            backgroundColor: 'unset',
        },
        '.MuiFormLabel-root': {
            color: COLOURS.DarkGrey,
        },
        '&.MuiToolbar-root': {
            width: 'unset',
        },
    },
};
export const MuiFormControl = {
    styleOverrides,
};
const labelStyleOverrides = {
    root: {
        marginTop: '0 !important', //overwrite library inline style
    },
    label: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        marginTop: 0,
        color: COLOURS.Black,
    },
};
export const MuiFormControlLabel = {
    styleOverrides: labelStyleOverrides,
};
