import {
  useMemo,
  useState,
  useCallback,
  useRef,
  createRef,
  useEffect,
} from 'react';
import Geocode from 'react-geocode';
import { Container, Grid } from '@mui/material';
import _ from 'lodash';
import { AnalyticsService } from 'hive-analytics-react';
import { Bounds, ChangeEventValue, Coords } from 'google-map-react';
import {
  useResourceLocationsByCoords,
  useDeviceCaps,
  REGEX_POSTAL_CODE,
  Coord,
  ResourceLocation,
  UserState,
} from 'ontariohealth-shared-utilities';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'react-storage-complete';

import SearchMap from './SearchMap';
import SearchResultsComponent from './SearchResults';
import SearchSidebar from './SearchSideBar';
import SearchDivider from './Divider';
import { MAP_DEFAULT_CENTER } from '../../content/constants';
import { filterByNearest } from './utils';

export default function Search(): JSX.Element {
  const { t } = useTranslation();
  // TODO: remove unused states and functions
  const [addressCoords, setAddressCoords] = useState<Coords | undefined>();
  const [typedAddress, setTypedAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [errorShown, setErrorShown] = useState(false);
  const [distance] = useState(50);
  const [limit] = useState(100);
  const [noResults, setNoResults] = useState(false);
  const { isMobile } = useDeviceCaps();

  const [bounds, setBounds] = useState<Bounds | undefined>();
  const [center, setCenter] = useState(MAP_DEFAULT_CENTER);

  const postalCodeRef = useRef<HTMLInputElement>(null);
  const [userSession, setUserSession] = useSessionStorage<UserState>('user', {
    postalCode: null,
  });

  const onMapChange = (value: ChangeEventValue) => {
    setBounds(value.bounds);
  };

  // Fetch whatever we get from the bounds of the map
  const locations = useResourceLocationsByCoords(bounds?.nw, bounds?.se);

  // Filter the locations by the nearest to address
  const filteredLocations: ResourceLocation[] = useMemo(() => {
    // if no address, return all locations
    if (!addressCoords) {
      return [];
    }

    return filterByNearest(locations, addressCoords, distance, limit);
  }, [locations, distance, addressCoords, limit]);

  const setAddress = useCallback(
    (address: string | null) => {
      if (address === null) {
        setUserSession({
          ...userSession,
          postalCode: null,
        });
        return;
      }

      setAddressCoords(undefined); // need to clear while the async is running
      setTypedAddress(address);
      setErrorShown(false);

      Geocode.fromAddress(address).then(
        (response: {
          results: { geometry: { location: { lat: any; lng: any } } }[];
        }) => {
          setAddressCoords(response.results[0].geometry.location);
          setCenter(response.results[0].geometry.location);
          setNoResults(false);
        },
        (error: any) => {
          setAddressError(error.message);
        }
      );
    },
    [setUserSession, userSession]
  );

  const onLocationReceived = useCallback(
    (coord: Coord) => {
      setAddressCoords(coord);
      setCenter(coord);
    },
    [setAddressCoords, setCenter]
  );

  // On Postal Code 'Go' button click
  const onSearch = useCallback(() => {
    if (!postalCodeRef.current) {
      return;
    }
    const postalCode = postalCodeRef.current.value.trim();

    if (REGEX_POSTAL_CODE.exec(postalCode)) {
      setAddress(postalCode);
      setUserSession({
        ...userSession,
        postalCode,
      });
      setErrorShown(false);
    } else {
      setAddressError(t('search.error.postal_code'));
      setErrorShown(true);
    }
  }, [setAddress, setUserSession, userSession, t]);

  const refs = useMemo(
    () =>
      _.reduce(
        filteredLocations,
        (acc: any, a: object, index: number) => {
          acc[index] = createRef();
          return acc;
        },
        {}
      ),
    [filteredLocations]
  );

  const handleMapIconClick = useCallback(
    (id: number) => {
      const clickedLocation = filteredLocations[id];

      setCenter({
        lat: clickedLocation.properties.lat,
        lng: clickedLocation.properties.lng,
      });

      AnalyticsService.logClick('LOCATION_CLICK', {
        location: clickedLocation.properties.name,
      });

      refs[id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
    [filteredLocations, refs]
  );

  const SearchResults = useCallback(() => {
    const marginTop = isMobile ? 4 : 6;
    return (
      <>
        <SearchDivider marginTop={marginTop} marginBottom={2} />
        <SearchResultsComponent
          refs={refs}
          locations={filteredLocations}
          setCenter={setCenter}
        />
      </>
    );
  }, [isMobile, refs, filteredLocations]);

  useEffect(() => {
    if (!userSession?.postalCode) {
      return;
    }

    setAddress(userSession.postalCode);
  }, [setAddress, userSession?.postalCode]);

  return (
    <Container>
      <Grid
        container
        padding={isMobile ? 6 : 8}
        spacing={6}
        justifyContent="space-between"
      >
        <Grid
          item
          xs={12}
          md={6}
          alignItems="flex-start"
          maxWidth={{ xs: '100%', md: '53.75em' }}
          marginX={{ xs: 0, md: 'auto' }}
        >
          <SearchSidebar
            typedAddress={typedAddress}
            onSearch={onSearch}
            noResults={noResults}
            addressError={addressError}
            setAddressError={setAddressError}
            errorShown={errorShown}
            setErrorShown={setErrorShown}
            postalCodeRef={postalCodeRef}
            onLocationReceived={onLocationReceived}
          />
          {!isMobile && <SearchResults />}
        </Grid>
        <Grid item xs={12} md={6}>
          <SearchMap
            locations={filteredLocations}
            mapIconClick={handleMapIconClick}
            onChange={onMapChange}
            center={center}
          />
          {isMobile && <SearchResults />}
        </Grid>
      </Grid>
    </Container>
  );
}
