import {
  Stack,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import TabPanel from '../TabPanel';
import { PATH_SCANNER } from '../../content/constants';

interface EmailNoticeProps {
  base: string;
  nameStr: string;
}

const EmailNotice = ({ base, nameStr }: EmailNoticeProps): JSX.Element => {
  const displayHTML = base.replace('{{name}}', `<strong>${nameStr}</strong>`);
  return <Typography>{ReactHtmlParser(displayHTML)}</Typography>;
};

enum TabValues {
  landing,
  register,
}

export default function SignUpSuccess(): JSX.Element {
  const { t } = useTranslation();
  const [value, setValue] = useState(TabValues.landing);
  const navigate = useNavigate();

  return (
    <Stack padding={5}>
      <TabPanel value={value} index={0}>
        <Typography variant="h4" className="search__header" marginBottom={4}>
          {t(`signUp.account_created`)}
        </Typography>
        <EmailNotice base={t(`signUp.account_sub1`)} nameStr="Timon LeDain" />

        <Typography marginTop={3}>{t('signUp.account_sub2')}</Typography>

        <Stack flexDirection="row" marginTop={10}>
          <Button
            variant="contained"
            className="secondary_button"
            color="inherit"
          >
            {t('signUp.view')}
          </Button>
          <Button
            onClick={() => setValue(TabValues.register)}
            variant="contained"
            color="primary"
            className="margin_left"
          >
            {t('signUp.register')}
          </Button>
        </Stack>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Typography variant="h4" className="search__header" marginBottom={4}>
          {t(`signUp.get_started`)}
        </Typography>
        <Typography>{t(`signUp.register_subheader`)}</Typography>
        <Typography marginTop={3}>{t(`signUp.register_subheader2`)}</Typography>

        <Stack margin={5}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="me"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="me"
                className="bold"
                control={<Radio />}
                label={t(`signUp.me`)}
              />
              <FormControlLabel
                value="someone"
                className="bold"
                control={<Radio />}
                label={t(`signUp.someone_else`)}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Button
          onClick={() => navigate(PATH_SCANNER)}
          variant="contained"
          color="primary"
        >
          {t('common.continue')}
        </Button>
      </TabPanel>
    </Stack>
  );
}
