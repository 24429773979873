import { ComponentsOverrides } from '@mui/material';
import { COLOURS, spacing } from 'ontariohealth-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiFormControl'] = {
  root: {
    marginTop: spacing,
    width: '100%',
    '.MuiFilledInput-root': {
      marginRight: spacing * 3,
      fontSize: '16px',
      lineHeight: '16px',
      backgroundColor: 'unset',
    },
    '.MuiFilledInput-input': {
      borderColor: COLOURS.Primary,
      paddingTop: spacing * 3,
    },
    '.MuiFormLabel-root': {
      color: COLOURS.DarkGrey,
    },
    '&.MuiToolbar-root': {
      width: 'unset',
    },
    '.hidden&': {
      display: 'none',
    },
  },
};

export const MuiFormControl = {
  styleOverrides,
};

const labelStyleOverrides: ComponentsOverrides['MuiFormControlLabel'] = {
  root: {
    '&.checkbox': {
      marginLeft: spacing,
    },
    '&.bold': {
      '.MuiTypography-root': {
        fontWeight: 'bold',
        alignSelf: 'center',
        marginTop: 0,
        fontSize: spacing * 4,
      },
    },
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: COLOURS.Black,
    padding: 0,
    marginTop: spacing * 4,
    display: 'flex',
    alignSelf: 'flex-start',
  },
};

export const MuiFormControlLabel = {
  styleOverrides: labelStyleOverrides,
};
