import { assertLoggableConnection, } from 'hive-analytics-react';
import _ from 'lodash';
import moment from 'moment';
function createUser(user, redirectUri) {
    const connection = assertLoggableConnection();
    return connection.bee.api.invoke('createUser', user, redirectUri);
}
function updateUser(id, props) {
    if (_.isEmpty(props) || !id) {
        return Promise.resolve();
    }
    const connection = assertLoggableConnection();
    return connection.bee.api.invoke('updateUser', id, props);
}
function formatDate(date) {
    if (!date) {
        return '';
    }
    return moment(date).format('YYYY');
}
export const UserService = {
    createUser,
    updateUser,
    formatDate,
};
