import { Container, Grid, Link, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLOURS } from 'ontariohealth-shared-utilities';

import OHLogo from '../assets/footer_logo.png';

const FooterTypography = styled(Typography)({
  color: COLOURS.LightGrey,
}) as typeof Typography;

export default function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <footer>
      <Container>
        <Grid container gap={2} paddingX={6} paddingY={4}>
          <Grid item xs={12} sm={8}>
            <FooterTypography
              component="p"
              align="justify"
              variant="caption"
              // Note: removing footer for mobile view for demo purposes
              display={{ xs: 'none', sm: 'block' }}
            >
              {t('footer.statement')}{' '}
              <span>
                <Link
                  href={`mailto:${t('footer.contactEmail')}`}
                  component="a"
                  color="inherit"
                >
                  {t('footer.contactEmail')}
                </Link>
              </span>
            </FooterTypography>
          </Grid>
          <Grid
            item
            xs={12}
            sm="auto"
            display={{ xs: 'flex', md: 'block' }}
            justifyContent={{ xs: 'flex-end' }}
            marginLeft={{ xs: null, sm: 'auto' }}
          >
            <img
              src={OHLogo}
              alt="Ontario Health Logo"
              style={{ maxHeight: '53px' }}
            />
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
