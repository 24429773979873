import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useUsers, useFeatureFlags } from '../hooks';
import { CommonDataContext } from './CommonDataContext';
export function CommonDataContextProvider({ children, }) {
    const users = useUsers();
    const featureFlags = useFeatureFlags();
    const value = useMemo(() => ({
        users: users || [],
        featureFlags,
    }), [users, featureFlags]);
    return (_jsx(CommonDataContext.Provider, Object.assign({ value: value }, { children: children })));
}
