import { Stack, Typography, Button, Checkbox, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH_SIGNUP } from './constants';
import TabPanel from '../components/TabPanel';
import WelcomeIcon from '../assets/welcome.png';

enum TabValues {
  welcome,
  terms,
}

export default function Welcome(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState(TabValues.welcome);
  const [consentReceived, setConsentReceived] = useState<boolean>(false);

  return (
    <Stack padding={5}>
      <TabPanel value={value} index={0}>
        <Typography variant="h4" className="search__header" marginBottom={6}>
          {t(`welcome.header`)}
        </Typography>

        <Typography marginBottom={12}>{t(`welcome.subheader`)}</Typography>

        <Stack alignItems="center">
          <img src={WelcomeIcon} alt="Welcome Icon" width={200} />
        </Stack>

        <Typography marginBottom={15} marginTop={12}>
          {t(`welcome.subheader2`)}
        </Typography>

        <Stack display="flex" flexDirection="row" marginTop={10}>
          <Button
            onClick={() => setValue(value + 1)}
            variant="contained"
            color="primary"
          >
            {t('welcome.create')}
          </Button>
        </Stack>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Typography variant="h4" className="search__header" marginBottom={4}>
          {t(`welcome.create`)}
        </Typography>

        <Typography marginTop={5} marginBottom={10}>
          {t(`welcome.terms_subheader`)}
        </Typography>

        <Stack flexDirection="row" alignItems="center">
          <Checkbox
            className="checkbox"
            value={consentReceived}
            onChange={() => setConsentReceived(!consentReceived)}
          />
          <Typography>
            {t('welcome.confirm_terms')}
            <Link
              target="_blank"
              href="https://covid19.ontariohealth.ca/app-terms?returnPath=/app-home"
            >
              {t('welcome.terms')}
            </Link>
          </Typography>
        </Stack>

        <Stack display="flex" flexDirection="row" marginTop={8}>
          <Button
            onClick={() => setValue(value - 1)}
            variant="contained"
            color="inherit"
            className="secondary_button"
          >
            {t('common.previous')}
          </Button>
          <Button
            onClick={() => navigate(PATH_SIGNUP)}
            variant="contained"
            disabled={!consentReceived}
            color="primary"
            className="margin_left"
          >
            {t('common.continue')}
          </Button>
        </Stack>
      </TabPanel>
    </Stack>
  );
}
