import { PrivacyPolicy_en as privacyPolicyText } from './PrivacyPolicy';
import { TermsOfService_en as termsOfServiceText } from './TermsOfService';

const APP_TITLE = 'Ontario Health';

export const en = {
  app: {
    title: APP_TITLE,
  },
  header: {
    title: APP_TITLE,
    login: 'Sign In',
    account: 'Account',
    results: 'Test Results ({{count}})',
    submit: 'Submit a kit',
    logout: 'Sign Out',
  },
  footer: {
    // must append OH email to end of statement
    statement: `The information on this website is intended for informational purposes only
    and is not a substitute for medical advice. Always consult your health care provider if
    you have questions or concerns. The use of the information on this website does not
    create a physician-patient relationship between Ontario Health and you. Ontario Health
    is committed to ensuring accessible services and communications to individuals with
    disabilities. To receive any information on this website in an alternate format, please
    contact Ontario Health's Communications Department at: 1-877-280-8538, TTY
    1-800-855-0511, or by email at`,
    contactUs: 'Need help? Contact us.',
    contactEmail: 'info@ontariohealth.ca',
    privacyPolicyTitle: 'Privacy Policy',
    privacyPolicy: 'privacy policy',
    privacyPolicyText,
    termsOfServiceTitle: 'Terms of Service',
    termsOfService: 'terms of service',
    termsOfServiceText,
  },
  page_titles: {
    sign_in: 'Sign In Page',
    sign_up: 'Sign Up Page',
    home: 'Search for Resources',
  },
  common: {
    next: 'Next',
    cancel: 'Cancel',
    back: 'Back',
    save: 'Save',
    continue: 'Continue',
    previous: 'Previous',
    success_submit: 'Successfully Submitted',
    failed_submit: 'Submission Failed',
    required: 'This is a required field',
  },
  content: {
    care_finder: {
      where: 'Where are you looking for services?',
      where_placeholder: 'Enter Postal Code',
      distance: 'Within what distance?',
      search: 'Find your Care Specialist',
      search_results_title: 'Current Search Criteria',
      modify: 'Modify Search Criteria',
      result_count: '{{resultCount}} matches to criteria',
      km_display: '{{value}} km',
      no_results_header: 'No Services were found with current criteria.',
      no_results_subheader:
        'Please increase search distance, or expand services selection.',
      postal_code_error: 'Postal code not found',
      learn_more: 'Learn More',
      distance_selected: 'Distance',
      location_selected: 'Location',
      not_applicable: 'Not applicable',
    },
  },
  welcome: {
    header: 'Get Started',
    subheader:
      "Welcome to Ontario Health's dedicated COVID-19 PCR testing kit registration portal.",
    subheader2:
      'Create an account to register kits, find convenient drop off locations and receive notifications with the results. You’ll need your Ontario Green Health Card to register.',
    create: 'Create Account',
    terms_subheader:
      'Please review our Terms of Use to complete account creation.',
    confirm_terms: 'I confirm that I have read, understood and accept the ',
    terms: 'Terms of Use',
  },
  signUp: {
    get_started: 'Get Started',
    tab_1_subheader:
      'Lets start with some basics, please clarify your name, email and phone number',
    tab_4_subheader:
      'Click the link we just sent to {{ email }} to confirm your email address and then head back here...',
    header: 'Create Account',
    subheader:
      "You'll need to enter your GREEN HEALTH CARD information to create an account",
    info_subheader: '',
    first_name: 'FIRST NAME',
    first_name_placeholder: 'e.g John',
    last_name: 'FAMILY NAME',
    last_name_placeholder: 'e.g Smith',
    phone_number: 'PHONE NUMBER',
    phone_number_placeholder: 'e.g 123-456-7890',
    sms_opt_in: 'I consent to receiving sms updates about my results',
    email: 'EMAIL',
    email_placeholder: 'e.g mrpotter@gmail.com',
    email_opt_in: 'I consent to receiving email updates about my results',
    birthday: 'BIRTHDATE',
    birthday_placeholder: 'e.g 24/04/1980',
    postal_code: 'POSTAL CODE',
    postal_code_placeholder: 'e.g K1X 2X6',
    health_card: '10 DIGIT HEALTHCARD #',
    health_card_placeholder: 'e.g 3948753948',
    version_code: '2-LETTER VERSION CODE',
    version_code_placeholder: 'e.g OH',
    back_code: '9-CHARACTER CODE ON THE BACK OF THE HEALTH CARD',
    back_code_placeholder: 'e.g 123456789',
    account_created: 'Account Created',
    account_sub1: 'Welcome to Ontario Health {{name}}!',
    account_sub2:
      'From here you can submit and view COVID-19 testing results and access other Ontario Health services.',
    view: 'View Account',
    register: 'Register a kit',
    register_subheader:
      "You'll need to create an account to register a COVID-19 testing kit.",
    register_subheader2: 'Who are you registering the kit for?',
    me: 'Me',
    someone_else: 'Someone Else',
    register_button: 'Register',
    success_header: 'Thank you for your submission',
    success_message:
      'Your submission has been received. You will be receiving an email with instructions on how to complete your account. You will be notified when your submission has been reviewed.',
  },
  barcode: {
    register: 'Register Kit',
    register_subheader:
      "Locate your COVID-19 test kit's barcode. You can either scan it with your camera or enter the 10 digit serial in manually.",
    register_button: 'Register',
    enter_serial: 'ENTER KIT SERIAL NUMBER',
    serial_placeholder: 'e.g 12345678910',
    serial_help: 'SERIAL NUMBER',
    scan: 'SCAN BARCODE',
    scan_button: 'Use Camera',
    difficulties: 'Having difficulties scanning?',
    android: 'Android instructions',
    iphone: 'iPhone instructions',
    desktop: 'Scanning from a Desktop/Laptop',
    registered: 'Kit Registered!',
    registered_subheader:
      'All done, now you need to locate your nearest health centre to drop the kit off.',
    nearest: 'Locate Nearest Health Center',
    barcodeImageAlt: 'Arrow indicating serial number',
  },
  not_implemented: 'State "{{connectionState}}" not implemented"',
  search: {
    header: 'Find a Kit Drop Location',
    subheader:
      'Find a convenient location to drop off a COVID-19 kit for testing.',
    open_link: 'Open with Google Maps',
    content: {
      or: 'OR',
    },
    button: {
      locate_me: 'Locate Me',
      go: 'Go',
      postal_code: 'Postal Code',
    },
    error: {
      postal_code: 'Postal code not found',
      geolocation: 'Geolocation turned off, please check your device settings',
    },
  },
};
