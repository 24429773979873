import { Dispatch, PropsWithRef, SetStateAction } from 'react';
import _ from 'lodash';
import {
  Stack,
  Typography,
  Card,
  CardActionArea,
  Box,
  styled,
  Link,
} from '@mui/material';
import { COLOURS, ResourceLocation } from 'ontariohealth-shared-utilities';
import { useTranslation } from 'react-i18next';

import MapItem from './MapItem';
import { getGoogleMapsUrl } from './utils';

export interface SearchResultCardProps {
  location: ResourceLocation;
  index: number;
  onClick?: () => void;
}

const StyledCard = styled(Card)({
  boxShadow: 'none',
  padding: '0px',
  borderRadius: '0px',
  borderBottom: `1px solid ${COLOURS.BorderLight}`,
});

const StyledCardActionArea = styled(CardActionArea)({
  paddingTop: '1.8em',
  paddingBottom: '1.8em',
});

const TypographyHeader = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1rem',
});

function SearchResultCard({
  location,
  index,
  onClick,
}: PropsWithRef<SearchResultCardProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledCard className="care-finder-result" key={index}>
      <StyledCardActionArea onClick={onClick}>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <MapItem index={index} />

          <Stack direction="column">
            <TypographyHeader variant="subtitle2" textTransform="capitalize">
              {location.properties.name}
            </TypographyHeader>
            <Typography variant="caption" textTransform="capitalize">
              {location.properties.address}
            </Typography>
            <Link
              variant="caption"
              target="_blank"
              href={getGoogleMapsUrl(location)}
            >
              {t('search.open_link')}
            </Link>
          </Stack>
        </Stack>
      </StyledCardActionArea>
    </StyledCard>
  );
}

export interface SearchResultsProps {
  locations: ResourceLocation[];
  refs?: any[];
  setCenter: Dispatch<SetStateAction<{ lat: number; lng: number }>>;
}

export default function SearchResults({
  locations,
  refs,
  setCenter,
}: PropsWithRef<SearchResultsProps>): JSX.Element | null {
  return !_.isEmpty(locations) ? (
    <>
      <Stack
        flexDirection="column"
        maxHeight="80vh"
        overflow="overlay"
        width="100%"
      >
        {_.map(locations, (location: ResourceLocation, index: number) => {
          const onClick = () => {
            setCenter({
              lat: location.properties.lat,
              lng: location.properties.lng,
            });
          };
          return (
            <Box key={index} ref={_.get(refs, index)}>
              <SearchResultCard
                location={location}
                index={index}
                onClick={onClick}
              />
            </Box>
          );
        })}
      </Stack>
    </>
  ) : null;
}
