import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useHiveConfig } from 'hive-react-utils';
import { ConnectionService } from 'hive-client-utils';
import { PATH_SEARCH } from '../content/constants';

function Progress(): JSX.Element {
  // TODO: make pretty
  return <CircularProgress />;
}

function Error({ error }: { error: string }): JSX.Element {
  // TODO: make pretty
  return <Typography color="error">{error}</Typography>;
}

export default function Authenticated(): JSX.Element {
  const [progress, setProgress] = useState(true);
  const [code, setCode] = useState<string | null>(null);
  const [error, setError] = useState<any>();
  const hiveConfig = useHiveConfig();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setCode(params.get('code'));
  }, []);

  useEffect(() => {
    if (!code || !hiveConfig) {
      return;
    }

    ConnectionService.signInFromOidcCode(hiveConfig, code)
      .then(() => {
        setProgress(false);
      })
      .catch(setError);
  }, [hiveConfig, code]);

  const content = useMemo<ReactElement>(() => {
    if (error) {
      return <Error error={error} />;
    }

    if (progress) {
      return <Progress />;
    }

    return <Navigate to={PATH_SEARCH} />;
  }, [error, progress]);

  return (
    <Stack flexGrow="1" justifyContent="center" alignItems="center">
      {content}
    </Stack>
  );
}
