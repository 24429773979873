import {
  Stack,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRef, useCallback, useState, useEffect } from 'react';
import {
  UserService,
  useApiCallWithMessageBar,
  UserProperties,
  COLOURS,
  UserState,
} from 'ontariohealth-shared-utilities';
import { Formik, Form, FormikProps, Field, FieldProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'react-storage-complete';

import { PATH_SEARCH, PATH_SIGNUP_LANDING, PATH_WELCOME } from './constants';
import { UserFields, initialUserValues } from '../components/utils';
import TabPanel from '../components/TabPanel';
import GetStarted from '../components/signUp/GetStarted';
import { getNoSpacesFieldProps } from '../components/utils';

enum TabValues {
  health,
  birthday,
  email,
  email_success,
}

export default function SignUp(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [value, setValue] = useState(TabValues.health);

  const redirectUri = `${window.location.origin}${PATH_SEARCH}`;

  const initialValues = initialUserValues();

  const formRef = useRef<FormikProps<UserFields>>(null);
  const healthFormRef = useRef<FormikProps<UserFields>>(null);
  const infoFormRef = useRef<FormikProps<UserFields>>(null);

  const [userSession, setUserSession] = useSessionStorage<UserState>('user', {
    postalCode: null,
  });

  // scroll to the top when we change tabs
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!true,
    execute: (user: UserProperties) =>
      UserService.createUser(user!, redirectUri),
    success: {
      message: t('common.success_submit'),
      onResult: () => navigate(PATH_SIGNUP_LANDING),
    },
    failure: {
      message: t('common.failed_submit'),
      onResult: (e) => {
        let msg = e?.content?.message?.message.split(':');
        if (msg.length > 1) {
          msg = msg[1].replace('"', ''); // user exists with same username
        } else {
          // invalid value and associated field
          msg = `${msg}: ${
            e?.content?.message?.errors[0].dataPath.split('.')[1]
          }`;
        }

        setErrorMessage(msg);
      },
    },
  });

  const handleFormikSubmit = useCallback(() => {
    if (
      (value === TabValues.health &&
        (!healthFormRef.current?.values.backCode ||
          !healthFormRef.current?.values.versionCode ||
          !healthFormRef.current?.values.healthCard)) ||
      (value === TabValues.birthday &&
        (!infoFormRef.current?.values?.postalCode ||
          !infoFormRef.current?.values?.birthday))
    ) {
      return;
    }

    setUserSession({
      ...userSession,
      postalCode: infoFormRef?.current?.values?.postalCode || null,
    });

    setValue(value + 1);
  }, [
    setValue,
    value,
    infoFormRef,
    healthFormRef,
    userSession,
    setUserSession,
  ]);

  const handleActualFormikSubmit = useCallback(
    (fields: UserFields) => {
      setValue(value + 1);
      return;

      // unreachable, leaving in case we need it later
      const formattedUser: UserProperties = {
        birthday: fields.birthday,
        firstName: formRef?.current?.values?.firstName || '',
        lastName: formRef?.current?.values?.lastName || '',
        phoneNumber: formRef?.current?.values?.phoneNumber || '',
        email: formRef?.current?.values?.email || '',
        smsOptIn: formRef?.current?.values?.smsOptIn || false,
        emailOptIn: formRef?.current?.values?.emailOptIn || false,
        postalCode: fields.postalCode,
        healthCard: healthFormRef?.current?.values?.healthCard || '',
        versionCode: healthFormRef?.current?.values?.versionCode || '',
        backCode: healthFormRef?.current?.values?.backCode || '',
      };

      onSubmit(formattedUser);
    },
    [onSubmit, formRef, healthFormRef, value, setValue]
  );

  return (
    <Stack padding={5} justifyContent="flex-start">
      <TabPanel value={value} index={0}>
        <Typography variant="h4" className="search__header" marginBottom={4}>
          {t(`signUp.header`)}
        </Typography>

        <Typography>{t(`signUp.subheader`)}</Typography>
        <Formik
          enableReinitialize
          innerRef={healthFormRef}
          onSubmit={handleFormikSubmit}
          initialValues={initialValues}
        >
          <Form className="formik-flex">
            <Stack flexGrow="1" position="relative" flex={1}>
              <Field name="healthCard">
                {({ field }: FieldProps) => (
                  <FormControlLabel
                    label={t('signUp.health_card')}
                    labelPlacement="top"
                    control={
                      <TextField
                        required
                        aria-required
                        margin="normal"
                        variant="filled"
                        placeholder={t('signUp.health_card_placeholder')}
                        inputProps={{ maxLength: 10, minLength: 10 }}
                        {...getNoSpacesFieldProps(field)}
                      />
                    }
                  />
                )}
              </Field>
            </Stack>

            <Stack flexGrow="1" position="relative" flex={1}>
              <Field name="versionCode">
                {({ field }: FieldProps) => (
                  <FormControlLabel
                    label={t('signUp.version_code')}
                    labelPlacement="top"
                    control={
                      <TextField
                        required
                        aria-required
                        margin="normal"
                        variant="filled"
                        placeholder={t('signUp.version_code_placeholder')}
                        inputProps={{ maxLength: 2, minLength: 2 }}
                        {...getNoSpacesFieldProps(field)}
                      />
                    }
                  />
                )}
              </Field>
            </Stack>

            <Field name="backCode">
              {({ field }: FieldProps) => (
                <FormControlLabel
                  label={t('signUp.back_code')}
                  labelPlacement="top"
                  control={
                    <TextField
                      required
                      aria-required
                      margin="normal"
                      variant="filled"
                      placeholder={t('signUp.back_code_placeholder')}
                      inputProps={{ maxLength: 9, minLength: 9 }}
                      {...getNoSpacesFieldProps(field)}
                    />
                  }
                />
              )}
            </Field>

            <Stack display="flex" flexDirection="row" marginTop={4}>
              <Button
                className="secondary_button"
                onClick={() => navigate(PATH_WELCOME)}
                variant="contained"
                color="inherit"
              >
                {t('common.previous')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="margin_left"
              >
                {t('common.continue')}
              </Button>
            </Stack>
          </Form>
        </Formik>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Typography variant="h4" className="search__header" marginBottom={4}>
          {t(`signUp.header`)}
        </Typography>

        <Typography>{t(`signUp.info_subheader`)}</Typography>

        <Formik
          enableReinitialize
          innerRef={infoFormRef}
          onSubmit={handleFormikSubmit}
          initialValues={initialValues}
        >
          <Form className="formik-flex">
            <Field name="birthday">
              {({ field }: FieldProps) => (
                <FormControlLabel
                  label={t('signUp.birthday')}
                  labelPlacement="top"
                  control={
                    <TextField
                      required
                      aria-required
                      margin="normal"
                      variant="filled"
                      placeholder={t('signUp.birthday_placeholder')}
                      inputProps={{ maxLength: 10, minLength: 10 }}
                      {...field}
                    />
                  }
                />
              )}
            </Field>

            <Field name="postalCode">
              {({ field }: FieldProps) => (
                <FormControlLabel
                  label={t('signUp.postal_code')}
                  labelPlacement="top"
                  control={
                    <TextField
                      required
                      aria-required
                      margin="normal"
                      variant="filled"
                      placeholder={t('signUp.postal_code_placeholder')}
                      inputProps={{ maxLength: 7, minLength: 6 }}
                      {...field}
                    />
                  }
                />
              )}
            </Field>

            <Stack display="flex" flexDirection="row" marginTop={4}>
              <Button
                onClick={() => setValue(value - 1)}
                variant="contained"
                color="inherit"
                className="secondary_button"
              >
                {t('common.previous')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="margin_left"
              >
                {t('common.continue')}
              </Button>
            </Stack>

            {errorMessage && (
              <Typography
                variant="h5"
                color={COLOURS.Red}
                fontWeight="bold"
                marginTop={5}
              >
                {errorMessage}
              </Typography>
            )}
          </Form>
        </Formik>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Formik
          enableReinitialize
          innerRef={formRef}
          onSubmit={handleActualFormikSubmit}
          initialValues={initialValues}
        >
          <Form className="formik-flex">
            <Typography
              variant="h4"
              className="search__header"
              marginBottom={4}
            >
              {t(`signUp.get_started`)}
            </Typography>
            <Typography>{t(`signUp.tab_1_subheader`)}</Typography>

            <Field name="firstName">
              {({ field }: FieldProps) => (
                <FormControlLabel
                  label={t('signUp.first_name')}
                  labelPlacement="top"
                  control={
                    <TextField
                      required
                      aria-required
                      autoComplete="given-name"
                      margin="normal"
                      variant="filled"
                      placeholder={t('signUp.first_name_placeholder')}
                      {...field}
                    />
                  }
                />
              )}
            </Field>

            <Field name="lastName">
              {({ field }: FieldProps) => (
                <FormControlLabel
                  label={t('signUp.last_name')}
                  labelPlacement="top"
                  control={
                    <TextField
                      required
                      aria-required
                      autoComplete="family-name"
                      margin="normal"
                      variant="filled"
                      placeholder={t('signUp.last_name_placeholder')}
                      {...field}
                    />
                  }
                />
              )}
            </Field>

            <Field name="phoneNumber">
              {({ field }: FieldProps) => (
                <FormControlLabel
                  label={t('signUp.phone_number')}
                  labelPlacement="top"
                  control={
                    <TextField
                      required
                      aria-required
                      autoComplete="phone-number"
                      margin="normal"
                      variant="filled"
                      placeholder={t('signUp.phone_number_placeholder')}
                      inputProps={{ maxLength: 15, minLength: 10 }}
                      {...field}
                    />
                  }
                />
              )}
            </Field>

            <Field name="smsOptIn">
              {({ field }: FieldProps) => (
                <FormControlLabel
                  className="checkbox"
                  label={t('signUp.sms_opt_in')}
                  control={<Checkbox {...field} />}
                />
              )}
            </Field>

            <Field name="email">
              {({ field }: FieldProps) => (
                <FormControlLabel
                  label={t('signUp.email')}
                  labelPlacement="top"
                  control={
                    <TextField
                      required
                      aria-required
                      autoComplete="email"
                      margin="normal"
                      variant="filled"
                      placeholder={t('signUp.email_placeholder')}
                      {...getNoSpacesFieldProps(field)}
                    />
                  }
                />
              )}
            </Field>

            <Field name="emailOptIn" marginLeft={5}>
              {({ field }: FieldProps) => (
                <FormControlLabel
                  className="checkbox"
                  label={t('signUp.email_opt_in')}
                  control={<Checkbox {...field} />}
                />
              )}
            </Field>

            <Stack
              marginTop={5}
              maxWidth="fit-content"
              marginLeft={4}
              flexDirection="row"
            >
              <Button
                onClick={() => setValue(value - 1)}
                variant="contained"
                color="inherit"
                className="secondary_button"
              >
                {t('common.previous')}
              </Button>
              <Button
                className="margin_left"
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('common.continue')}
              </Button>
            </Stack>
          </Form>
        </Formik>
      </TabPanel>

      {/* TODO: I'm here, change flow to check email tab */}
      <GetStarted
        value={value}
        email={formRef?.current?.values?.email || ''}
        setValue={setValue}
      />
    </Stack>
  );
}
