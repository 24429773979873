import { PropsWithChildren } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Stack } from '@mui/material';

import Authenticating from '../components/Authenticating';
import Header from '../components/header/Header';
import Footer from '../components/Footer';
import Content from './Content';
import Search from '../components/search/Search';

function RouteElement({ children }: PropsWithChildren<any>): JSX.Element {
  return (
    <Stack height="100%">
      <Header />
      <Stack flexGrow="1">{children}</Stack>
      <Footer />
    </Stack>
  );
}

export default function Main(): JSX.Element {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/oidc"
          element={<RouteElement children={<Authenticating />} />}
        />
        <Route
          path="/search"
          element={<RouteElement children={<Search />} />}
        />

        <Route path="/*" element={<RouteElement children={<Content />} />} />

        <Route
          path="*"
          element={<RouteElement children={<Navigate to="/" />} />}
        />
      </Routes>
    </Router>
  );
}
