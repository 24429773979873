export const COLOURS = {
    Primary: '#00B2E3',
    Secondary: '#177CB5',
    LightShade: '#F7F7F8',
    LightGrey: '#B3B4B4',
    MediumGrey: '#A9A9A9',
    DarkGrey: '#363636',
    Disabled: '#979797',
    Black: '#000000',
    White: '#ffffff',
    Red: '#d32f2f',
    Green: '#017D02',
    LightYellow: '#FFFA6D',
    LightRed: '#FA4040',
    LightBlue: '#64b1ff',
    AccentYellow: '#F3D34F',
    BorderLight: '#DADADA',
};
