export const PATH_SEARCH = '/search';
export const PATH_SIGNUP = '/signup';
export const PATH_SIGNUP_LANDING = '/signup-success';
export const PATH_SCANNER = '/barcode';
export const PATH_SCANNER_SUCCESS = '/success';

export const PATH_WELCOME = '/welcome';

export const VALID_PHONE_NUMBERS = [
  '(555) 555-5555',
  '555 555 5555',
  '555-555-5555',
  '555.555.5555',
  '+1 555 555 5555',
  '555 555 5555 ext.123',
  '555 555 5555',
  '15555555555',
  '555-555-5555',
  '5555554444',
  '5555554444 ext.123',
];

// Default is Ottawa
export const MAP_DEFAULT_CENTER = {
  lat: 45.422666,
  lng: -75.699817,
};

export const MAP_DEFAULT_ZOOM = 15;
