import { Link as RouterLink } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';

import { ReactComponent as OntarioHealthLogo } from '../../assets/ontario_health_logo.svg';

export default function Logo(): JSX.Element {
  return (
    <Box display="inline-flex" flexGrow={1} paddingY={4}>
      <IconButton
        component={RouterLink}
        to="/"
        color="inherit"
        aria-label="logo"
        disableRipple
      >
        <OntarioHealthLogo />
      </IconButton>
    </Box>
  );
}
