import { COLOURS } from './colours';
export const spacing = 4;
export const elevation = 6;
export const fontFamily = {
// Uncomment any one of these to mess with Steve
// fontFamily: 'Comic Sans MS',
// fontFamily: 'Papyrus',
// fontFamily: 'Brush Script MT',
// fontFamily: 'Bradley Hand',
// fontFamily: 'Courier',
// fontFamily: 'Impact',
// fontFamily: 'Futura',
// fontFamily: 'Avenir',
// fontFamily: 'Didot',
// fontFamily: 'Constantia',
// fontFamily: 'Gill Sans',
// fontFamily: 'Aurebesh',
// fontFamily: 'Wingdings',
};
export const primary = {
    main: COLOURS.Primary,
    contrastText: COLOURS.White,
    dark: COLOURS.Secondary,
};
export const secondary = {
    main: COLOURS.Secondary,
    contrastText: COLOURS.White,
};
export const palette = {
    primary: primary,
    secondary: secondary,
};
