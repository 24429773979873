import { PropsWithRef, useCallback, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';

import Marker from '../../assets/marker.png';
import { MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM } from '../../content/constants';
import { ResourceLocation } from 'ontariohealth-shared-utilities';

const GOOGLE_API_KEY =
  process.env.REACT_APP_GOOGLE_API_KEY ||
  (window as any)._env_.REACT_APP_GOOGLE_API_KEY;

export interface MapProps {
  locations: any;
  mapIconClick: (v: number) => void;
  onChange?: (value: GoogleMapReact.ChangeEventValue) => void;
  center: {
    lat: number;
    lng: number;
  };
}

export default function SearchMap({
  locations,
  mapIconClick,
  onChange,
  center,
}: PropsWithRef<MapProps>): JSX.Element {
  // using any as type since google-maps-react is not typed
  const mapRef = useRef(null);
  const mapsRef = useRef(null);

  // !Note: using png here as marker as Google optimizes for raster images
  // https://cloud.google.com/blog/products/maps-platform/google-maps-platform-best-practices-optimization-and-performance-tips
  const createMarkers = useCallback(() => {
    if (!mapRef.current || !mapsRef.current) {
      return;
    }

    return _.map(locations, (location: ResourceLocation, i: number) => {
      const lat = location.properties.lat;
      const lng = location.properties.lng;

      const marker = new (mapsRef.current as any).Marker({
        position: {
          lat,
          lng,
        },
        map: mapRef.current,
        icon: {
          url: Marker,
          scaledSize: new (mapsRef.current as any).Size(30, 35),
          labelOrigin: new (mapsRef.current as any).Point(15, 14),
        },
        title: location.properties.name,
        label: {
          // marker styling and content
          text: `${i + 1}`,
          fontSize: '14px',
          color: 'white',
          fontWeight: 'bold',
        },
      });

      marker.addListener('click', () => {
        mapIconClick(i);
      });

      return marker;
    });
  }, [locations, mapIconClick, mapRef, mapsRef]);

  // delete markers when location changes and clear event listeners
  const deleteMarkers = useCallback(
    (markerArr: Array<any>) => {
      _.forEach(markerArr, (marker: any) => {
        (mapsRef.current as any).event.clearInstanceListeners(marker);

        marker.setMap(null);
        marker = null;
      });
    },
    [mapsRef]
  );

  useEffect(() => {
    const markers = createMarkers();
    return () => deleteMarkers(markers as any);
  }, [locations, createMarkers, deleteMarkers]);

  return (
    <Grid
      container
      minHeight="50vh"
      height={{ xs: '50vh', sm: '100%' }}
      width="100%"
      overflow="hidden"
      borderRadius={2}
    >
      <GoogleMapReact
        zoom={14}
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        yesIWantToUseGoogleMapApiInternals
        center={center}
        onChange={onChange}
        defaultCenter={MAP_DEFAULT_CENTER}
        defaultZoom={MAP_DEFAULT_ZOOM}
        onGoogleApiLoaded={({ map, maps }) => {
          // get access to core google maps api
          mapRef.current = map;
          mapsRef.current = maps;
        }}
      />
    </Grid>
  );
}
