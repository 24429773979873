import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as UserIcon } from '../../assets/user-icon.svg';
import HeaderMenu from './Menu';
import { useLocation } from 'react-router-dom';
import { PATH_SIGNUP, PATH_WELCOME } from '../../content/constants';

export default function User(): JSX.Element {
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const location = useLocation();
  const { t } = useTranslation();

  const handleOpenMenu = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  // TODO: replace true with isUser (useHasRole) after demo
  const isUser =
    location.pathname !== PATH_WELCOME && location.pathname !== PATH_SIGNUP;

  return (
    <div>
      <Button
        color="inherit"
        aria-label="menu"
        onClick={handleOpenMenu}
        aria-haspopup="true"
        aria-expanded={menuAnchor ? 'true' : undefined}
        aria-controls={menuAnchor ? 'menu-appbar' : undefined}
        startIcon={<UserIcon style={{ width: 'inherit', height: 'inherit' }} />}
        variant="outlined"
      >
        {isUser ? (
          <Typography color="initial">
            Timon LeDain
            {menuAnchor && <> &#9652;</>}
            {!menuAnchor && <> &#9662;</>}
          </Typography>
        ) : (
          <Typography color="initial">{t('header.login')}</Typography>
        )}
      </Button>
      <HeaderMenu anchorEl={menuAnchor} onClose={handleCloseMenu} />
    </div>
  );
}
