import { Navigate, Route, Routes } from 'react-router-dom';
import {
  PATH_SEARCH,
  PATH_SIGNUP_LANDING,
  PATH_SIGNUP,
  PATH_SCANNER,
  PATH_WELCOME,
  PATH_SCANNER_SUCCESS,
} from './constants';
import SignUpSuccess from '../components/signUp/SignUpSuccess';
import SignUp from './SignUp';
import Search from '../components/search/Search';
import BarcodeScan from '../components/signUp/BarcodeScan';
import Welcome from './Welcome';
import BarcodeSuccess from '../components/signUp/BarcodeSuccess';

export default function Content(): JSX.Element {
  return (
    <Routes>
      <Route path={PATH_SEARCH} element={<Search />} />
      <Route path={PATH_SIGNUP} element={<SignUp />} />
      <Route path={PATH_SCANNER} element={<BarcodeScan />} />
      <Route path={PATH_SCANNER_SUCCESS} element={<BarcodeSuccess />} />
      <Route path={PATH_SIGNUP_LANDING} element={<SignUpSuccess />} />
      <Route path={PATH_WELCOME} element={<Welcome />} />
      <Route path="*" element={<Navigate to={PATH_WELCOME} />} />
    </Routes>
  );
}
