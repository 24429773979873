import {
  Languages,
  UserProperties,
  ResourceLocationProperties,
} from 'ontariohealth-shared-utilities';
import _ from 'lodash';
import * as yup from 'yup';
import { FieldInputProps } from 'formik';

export interface LocationFields {
  name: string;
  phoneNumber: string;
  businessHours: string;
  [localizedName: string]: string;
}

export const locationTranslatableFields = ['name', 'businessHours'] as const;

export interface UserFields {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  birthday: string;
  postalCode: string;
  healthCard: string;
  versionCode: string;
  backCode: string;
  smsOptIn?: boolean;
  emailOptIn?: boolean;
}

export const getFormattedLanguages = (
  fields: { [x: string]: any },
  translatableFields: any
) => {
  const translations: any = {};

  _.each(Languages, (l: string) => {
    _.each(translatableFields, (tf) => {
      if (tf === 'businessHours') {
        translations[l] = {
          ...translations[l],
          [tf]: _.split(fields[`${tf}-${l}`] ?? fields[tf], ','),
        };
      } else {
        translations[l] = {
          ...translations[l],
          [tf]: fields[`${tf}-${l}`] ?? fields[tf],
        };
      }
    });
  });

  return translations;
};

export const initialUserValues = (user?: UserProperties) => {
  const iv: UserFields = {
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phoneNumber: user?.phoneNumber || '',
    birthday: user?.birthday || '',
    postalCode: user?.postalCode || '',
    healthCard: user?.healthCard || '',
    versionCode: user?.versionCode || '',
    backCode: user?.backCode || '',
    smsOptIn: user?.smsOptIn || false,
    emailOptIn: user?.emailOptIn || false,
  };

  return iv;
};

export const initialLocationValues = (
  language: string,
  location: ResourceLocationProperties | null = null
) => {
  const iv: LocationFields = {
    name: location?.name || '',
    phoneNumber: location?.phoneNumber || '',
    businessHours: _.toString(location?.businessHours) || '',
    address: location?.address || '',
  };

  _.each(
    _.filter(Languages, (lang) => lang !== language),
    (l: keyof typeof Languages) => {
      _.each(locationTranslatableFields, (tf) => {
        let initialValue = location?.languages?.[l]?.[tf] ?? '';

        if (Array.isArray(initialValue)) {
          initialValue = initialValue.join(',');
        }

        iv[`${tf}-${l}`] = initialValue;
      });
    }
  );

  return iv;
};

// Must pass t from const {t} = useTranslation() to get correct localization
// Note: t should be of the return type of {t}, using any for the moment
export const validationSchema = (t: any) =>
  yup.object().shape({
    phoneNumber: yup
      //break line
      .string()
      .matches(
        /^(\+\d{0,2}\s)?((\d{10}|\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})|(\d{11}|\d{12}))( ext\.\d{1,6})?$/,
        t('signUp.validation.matches.phone_number')
      )
      .required(t('signUp.validation.required.phone_number')),
  });

export const getNoSpacesFieldProps = (props: FieldInputProps<any>) => {
  const onChange = props.onChange;
  return {
    ...props,
    onChange: (e: any) => {
      e.target.value = e.target.value.trim().replace('-', '');
      onChange(e);
    },
  };
};
