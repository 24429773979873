import { useMemo, PropsWithRef, RefObject, useCallback, useState } from 'react';
import _ from 'lodash';
import { Typography, TextField, Button, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  COLOURS,
  Coord,
  REGEX_POSTAL_CODE,
  useDeviceCaps,
} from 'ontariohealth-shared-utilities';
import { useSessionStorage } from 'react-storage-complete';

import { ReactComponent as SearchIcon } from '../../assets/search_go_button.svg';
import { ReactComponent as LocateMeIcon } from '../../assets/locate_me.svg';

export interface SidebarProps {
  onSearch: () => void;
  typedAddress: string;
  noResults: boolean;
  addressError: string;
  setAddressError: (v: string) => void;
  errorShown: boolean;
  setErrorShown: (v: boolean) => void;
  postalCodeRef: RefObject<HTMLInputElement>;
  onLocationReceived: (coord: Coord) => void;
}

export default function SearchSidebar({
  onSearch,
  typedAddress,
  noResults,
  addressError,
  setAddressError,
  setErrorShown,
  errorShown,
  postalCodeRef,
  onLocationReceived,
}: PropsWithRef<SidebarProps>): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useDeviceCaps();
  const [geoLocWarn, setGeoLocWarn] = useState(false);

  const [userSession] = useSessionStorage('user', { postalCode: null });

  const onChange = useMemo(
    () =>
      _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value || null;
        if (!value) {
          setErrorShown(false);
          return;
        }

        const match = REGEX_POSTAL_CODE.exec(value.trim());

        if (match) {
          setErrorShown(false);
          setGeoLocWarn(false);
        } else {
          setAddressError(t('search.error.postal_code'));
          setErrorShown(true);
        }
      }, 500),
    [setAddressError, setErrorShown, t]
  );

  const locateUser = useCallback(() => {
    if (navigator.geolocation) {
      setGeoLocWarn(false);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          onLocationReceived({ lat: latitude, lng: longitude } as Coord);
        },
        (error) => {
          // TODO: more UI feedback on error / wait for design updates
          console.error(error.message);
          setGeoLocWarn(true);
        }
      );
    }
  }, [onLocationReceived]);

  return (
    <Grid container item spacing={4} marginBottom={{ xs: errorShown ? 4 : 0 }}>
      {noResults && (
        <Grid container item>
          <Typography fontWeight="bold" variant="h5" color="primary">
            {t('content.care_finder.no_results_header')}
          </Typography>
        </Grid>
      )}
      {geoLocWarn && (
        <Grid container item>
          <Typography fontWeight="bold" variant="h5" color="primary">
            {t('search.error.geolocation')}
          </Typography>
        </Grid>
      )}
      <Grid container item xs={12} spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" className="search__header" marginBottom={2}>
            {t('search.header')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="p" color={COLOURS.Black}>
            {t('search.subheader')}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box
          display="grid"
          grid-template-columns="repeat(auto-fit, minmax(min-content, 1fr))"
          gridAutoFlow="column"
          alignItems="center"
          justifyContent="start"
          gap={isMobile ? 2 : 8}
        >
          <Button
            aria-label="locate-me"
            aria-disabled={noResults}
            aria-pressed={noResults}
            variant="outlined"
            className="secondary_button no_padding"
            onClick={locateUser}
            disabled={noResults}
            color="inherit"
            startIcon={<LocateMeIcon className="search__icon-locate-btn" />}
          >
            {t('search.button.locate_me')}
          </Button>
          <Box display="flex" alignSelf="center">
            <Typography variant="overline" component="span">
              {t('search.content.or')}
            </Typography>
          </Box>
          <Box
            display="grid"
            grid-template-columns="repeat(auto-fit, minmax(min-content, 1fr))"
            gridAutoFlow="column"
            gap={4}
          >
            <TextField
              type="text"
              className="search__input-postal-code"
              FormHelperTextProps={{
                className: 'search__input-postal-code-error',
              }}
              defaultValue={userSession?.postalCode || typedAddress}
              onChange={onChange}
              variant="standard"
              placeholder={t('search.button.postal_code')}
              inputProps={{
                style: {
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                },
              }}
              error={errorShown}
              helperText={errorShown ? addressError : ''}
              inputRef={postalCodeRef}
            />
            <Button
              aria-label="search postal code"
              aria-disabled={noResults}
              aria-pressed={noResults}
              variant="contained"
              onClick={onSearch}
              disabled={noResults}
              className="no_padding"
              startIcon={<SearchIcon className="search-go-icon" />}
            >
              {t('search.button.go')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
