import { createContext, useContext, useMemo } from 'react';
import { User, useMyUser } from 'ontariohealth-shared-utilities';

export interface DataContextProps {
  user?: User;
}

export const DataContext = createContext<DataContextProps>({});

export function DataContextProvider({
  children,
}: {
  children: null | JSX.Element;
}) {
  // IMPORTANT: just keep data that does NOT change often and that can be used often.

  const user = useMyUser();

  const value = useMemo<DataContextProps>(
    () => ({
      user,
    }),
    [user]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export function useDataContext() {
  return useContext(DataContext);
}
