import { ReactComponent as MapPin } from '../../assets/map_pin.svg';
import { Box, Typography } from '@mui/material';
import { PropsWithRef } from 'react';

export interface MapItemProps {
  lat?: number;
  lng?: number;
  index: number;
  handleClick?: (v: number) => void;
}

export default function MapItem({
  index,
  handleClick = () => {},
}: PropsWithRef<MapItemProps>): JSX.Element {
  return (
    <Box
      position="relative"
      display="inline-flex"
      aria-label="click to zoom to drop off location"
      onClick={() => handleClick(index)}
    >
      <Typography className="map-item">{Number(index) + 1}</Typography>
      <MapPin className="map-pin" />
    </Box>
  );
}
