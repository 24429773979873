import { Coords } from 'google-map-react';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { ResourceLocation } from 'ontariohealth-shared-utilities';

// Converts numeric degrees to radians
const toRad = (Value: number) => {
  return (Value * Math.PI) / 180;
};

//This function takes in latitude and longitude of two location and returns the distance
//between them as the crow flies (in km)
const calcCrow = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var latRad = toRad(lat1);
  var latRad2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(latRad) *
      Math.cos(latRad2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

export interface ResourceLocationWithDistance extends ResourceLocation {
  distance: number;
}

export function filterByNearest(
  locations: ResourceLocation[],
  fromCoords: Coords,
  distance: number,
  limit: number = 100
): ResourceLocation[] {
  return _fp.flow([
    _fp.map((l: ResourceLocation) => ({
      ...l,
      distance: calcCrow(
        l.properties.lat,
        l.properties.lng,
        fromCoords.lat,
        fromCoords.lng
      ),
    })),
    _fp.filter((l: ResourceLocationWithDistance) => l.distance <= distance),
    _fp.orderBy(['distance'], ['asc']),
    _fp.slice(0, limit),
  ])(locations);
}

export const filterByType = (selectedServices: any, locations: any) => {
  return _.reduce(
    selectedServices,
    (selected: any, a) => {
      const found: { properties: { servicesOffered: string | any[] } }[] = [];
      _.each(
        locations,
        (location: { properties: { servicesOffered: string | any[] } }) => {
          if (location.properties.servicesOffered.indexOf(a) !== -1) {
            found.push(location);
          }
        }
      );
      selected = [...selected, ...found];
      return selected;
    },
    []
  );
};

export const findServices = (locations: any) => {
  return _.reduce(
    locations,
    (group: any, a) => {
      _.each(a.properties.servicesOffered, (type) => {
        group[type] = group[type] || [];
        group[type].push(a);
      });
      return group;
    },
    {}
  );
};

export const calculateYearsOfExperience = (yearStarted: string) => {
  const yearAsDate = new Date(yearStarted);
  const today = new Date();
  const years = new Date(today).getFullYear() - yearAsDate.getFullYear();
  return years.toString();
};

function formatString(str: string) {
  // Replace all spaces with "+"
  str = str.replace(/ /g, '+');

  // Remove anything that's not an alphabetic character or number
  str = str.replace(/[^a-zA-Z0-9+]/g, '');

  return _.escape(str);
}

export function getGoogleMapsUrl(location: ResourceLocation) {
  const cleanLoc = formatString(
    location.properties.name + ' ' + location.properties.address
  );
  const query = `https://www.google.com/maps/search/?api=1&query=${cleanLoc}`;

  return query;
}
