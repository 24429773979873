import { ComponentsOverrides } from '@mui/material';

const textFieldStyleOverrides: ComponentsOverrides['MuiFormHelperText'] = {
  root: {
    '.search__input-postal-code-error&.MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '100%',
    },
  },
};

export const MuiFormHelperText = {
  styleOverrides: textFieldStyleOverrides,
};
