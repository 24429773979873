import { ComponentsOverrides } from '@mui/material';
import { spacing, COLOURS } from 'ontariohealth-shared-utilities';

const buttonStyleOverrides: ComponentsOverrides['MuiButton'] = {
  //using any here because Theme type from @MUI doesn't satisfy eslint
  root: ({ theme }: any) => ({
    [theme.breakpoints.down('sm')]: {
      lineHeight: 2,
      height: spacing * 12,
      padding: `${spacing * 2}px ${spacing * 4}px`,
    },
    '&.margin_left': {
      marginLeft: spacing * 4,
    },
    '.set_height&': {
      height: spacing * 12,
    },
    '.secondary_button&': {
      backgroundColor: COLOURS.White,
      border: '1px solid #00b2e3',
    },
    '.no_padding&': {
      padding: `${spacing}px ${spacing * 2}px`,
      height: 'unset',
    },
  }),
};

export const MuiButton = {
  styleOverrides: buttonStyleOverrides,
};
