import { AnalyticsService } from 'hive-analytics-react';
import { ConnectionService } from 'hive-client-utils';
import { useLoginCallback } from 'hive-react-utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';

import {
  PATH_SCANNER,
  PATH_SEARCH,
  PATH_SIGNUP,
  PATH_WELCOME,
} from '../../content/constants';
import LanguageToggle from '../LanguageToggle';

const OIDC_PATH =
  process.env.PUBLIC_URL === '/' ? '/oidc' : `${process.env.PUBLIC_URL}/oidc`;

export default function HeaderMenu({
  anchorEl,
  onClose,
}: {
  anchorEl: Element | null;
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();
  // TODO: replace with useHasRole
  const isUser =
    location.pathname !== PATH_WELCOME && location.pathname !== PATH_SIGNUP;

  const handleLoginInternal = useLoginCallback(OIDC_PATH, onClose);
  const handleLogin = useCallback(() => {
    AnalyticsService.logClick('HEADER_MENU_LOGIN');
    handleLoginInternal();
  }, [handleLoginInternal]);

  const handleLogout = useCallback(() => {
    AnalyticsService.logClick('HEADER_MENU_LOGOUT');
    ConnectionService.releaseBee();
    onClose();
  }, [onClose]);

  const handleAccount = useCallback(() => {
    navigate(PATH_SEARCH);
    AnalyticsService.logClick('HEADER_MENU_ACCOUNT');
    onClose();
  }, [onClose, navigate]);

  const items = useMemo(() => {
    if (isUser) {
      return [
        <MenuItem key="language">
          <LanguageToggle />
        </MenuItem>,
        <MenuItem key="account" onClick={handleAccount}>
          <>&#9702; {t('header.account')}</>
        </MenuItem>,
        <MenuItem key="results" onClick={handleAccount}>
          <>&#9702; {t('header.results', { count: 0 })}</>
        </MenuItem>,
        <MenuItem key="submit" onClick={() => navigate(PATH_SCANNER)}>
          <>&#9702; {t('header.submit')}</>
        </MenuItem>,
        <MenuItem key="sign-out" onClick={handleLogout}>
          <>&#9702; {t('header.logout')}</>
        </MenuItem>,
      ];
    }

    return [
      <MenuItem key="sign-in" onClick={handleLogin}>
        <>{t('header.login')}</>
      </MenuItem>,
      <MenuItem key="language">
        <LanguageToggle />
      </MenuItem>,
    ];
  }, [t, isUser, handleLogin, handleLogout, handleAccount, navigate]);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={!!anchorEl}
      onClose={onClose}
    >
      {items}
    </Menu>
  );
}
