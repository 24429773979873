import {
  Stack,
  Typography,
  Button,
  TextField,
  Dialog,
  FormControlLabel,
  Link,
  List,
  ListItem,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { useNavigate } from 'react-router-dom';

import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Barcode from '../../assets/barcode_arrow.png';
import {
  PATH_SIGNUP_LANDING,
  PATH_SCANNER_SUCCESS,
} from '../../content/constants';
import { COLOURS } from 'ontariohealth-shared-utilities';

export default function BarcodeScan(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [code, setCode] = useState<string>('');
  const [stopStream, setStopStream] = useState<boolean>(false);
  const [openScanner, setOpenScanner] = useState<boolean>(false);

  const dismissQrReader = () => {
    // Stop the QR Reader stream (fixes issue where the browser freezes when closing the modal)
    setStopStream(true);
    setOpenScanner(false);
  };

  return (
    <Stack padding={5}>
      <Stack>
        <Typography variant="h4" className="search__header" marginBottom={4}>
          {t('signUp.register')}
        </Typography>
        <Typography>{t('barcode.register_subheader')}</Typography>
      </Stack>

      <Stack
        flexDirection="column"
        spacing={3}
        alignItems="center"
        borderBottom="1px dotted"
        marginBottom={3}
        paddingBottom={5}
      >
        <figure>
          <img
            alt={t('barcode.barcodeImageAlt')}
            src={Barcode}
            width="200"
            height="auto"
          />
          <figcaption style={{ fontSize: 'small', textAlign: 'center' }}>
            {t('barcode.serial_help')}
          </figcaption>
        </figure>
      </Stack>

      <Stack alignItems="baseline" marginBottom={4}>
        <FormControlLabel
          label={t('barcode.enter_serial')}
          labelPlacement="top"
          control={
            <TextField
              variant="filled"
              placeholder={t('barcode.serial_placeholder')}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          }
        />
      </Stack>

      <Stack sx={{ backgroundColor: COLOURS.LightShade }}>
        <Stack marginTop={5} marginLeft={3} paddingTop={5} spacing={3}>
          <Typography fontSize="small">{t('barcode.scan')}</Typography>
          <Stack maxWidth="fit-content">
            <Button
              className="secondary_button"
              variant="contained"
              color="inherit"
              startIcon={<CameraAltOutlinedIcon />}
              onClick={() => setOpenScanner(true)}
            >
              {t('barcode.scan_button')}
            </Button>
          </Stack>

          <Typography fontSize="small">{t('barcode.difficulties')}</Typography>
          <List>
            <ListItem sx={{ paddingLeft: '0' }}>
              <KeyboardArrowRightIcon />
              <Link href="#" fontSize="small">
                {t('barcode.desktop')}
              </Link>
            </ListItem>
            <ListItem sx={{ paddingLeft: '0' }}>
              <KeyboardArrowRightIcon />
              <Link href="#" fontSize="small">
                {t('barcode.iphone')}
              </Link>
            </ListItem>
            <ListItem sx={{ paddingLeft: '0' }}>
              <KeyboardArrowRightIcon />
              <Link href="#" fontSize="small">
                {t('barcode.android')}
              </Link>
            </ListItem>
          </List>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={3} paddingLeft={3}>
        <Button
          className="secondary_button"
          variant="contained"
          color="inherit"
          onClick={() => navigate(PATH_SIGNUP_LANDING)}
        >
          {t('common.previous')}
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={!code}
          onClick={() => navigate(PATH_SCANNER_SUCCESS)}
        >
          {t('signUp.register_button')}
        </Button>
      </Stack>

      <Dialog open={openScanner} onClose={() => setOpenScanner(false)}>
        <BarcodeScannerComponent
          width={400}
          height={300}
          onUpdate={(err, result: any) => {
            if (result) {
              setCode(result.text);
              dismissQrReader();
            }
            if (err) {
              console.warn(err);
            }
          }}
          stopStream={stopStream}
        />
        <Stack alignItems="center" marginBottom={5} marginTop={5}>
          <Button variant="contained" color="primary" onClick={dismissQrReader}>
            {t('common.cancel')}
          </Button>
        </Stack>
      </Dialog>
    </Stack>
  );
}
