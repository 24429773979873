import _ from 'lodash';
import { useMemo } from 'react';
import { useCurrentLanguage, useInstancesByQuery } from 'hive-react-utils';
import { KeysOfResourceLocationForLanguage, } from '../types/resourceLocation';
import { translateProps, processPhoneNumbers } from '../utils/utils';
const INVALID_COORDS = { lat: 91, lng: 181 };
// Note: no need for `| undefined` as the return type, as long as _.map
// is used, there will always at least be an empty array returned
export function useResourceLocationsByCoords(topLeft = INVALID_COORDS, bottomRight = INVALID_COORDS) {
    // TODO: debounce
    const rawLocations = useInstancesByQuery('ontariohealth', 'resourceLocationByCoord', 'resourceLocation', {}, bottomRight.lat, topLeft.lng, topLeft.lat, bottomRight.lng);
    const language = useCurrentLanguage();
    return useMemo(() => {
        return _.map(rawLocations, (r) => {
            const translatedProps = translateProps(r, language, KeysOfResourceLocationForLanguage);
            const phoneNumbers = processPhoneNumbers(r);
            return Object.assign(Object.assign({}, r), { properties: Object.assign(Object.assign(Object.assign({}, r.properties), translatedProps), { phoneNumbers }) });
        });
    }, [rawLocations, language]);
}
