import { PrivacyPolicy_fr as privacyPolicyText } from './PrivacyPolicy';
import { TermsOfService_fr as termsOfServiceText } from './TermsOfService';

const APP_TITLE = 'Santé Ontario';

export const fr = {
  app: {
    title: APP_TITLE,
  },
  header: {
    title: APP_TITLE,
    login: 'Connexion',
    account: 'Compte',
    results: 'Résultats du test ({{count}})',
    submit: 'Soumettre un kit',
    logout: 'Déconnexion',
  },
  footer: {
    statement: `Les renseignements figurant sur ce site Web ne sont fournis qu’à titre informatif
    et ne doivent pas remplacer un avis médical. Consultez toujours votre fournisseur de soins de
    santé en cas de questions ou d’inquiétudes. L’utilisation des renseignements contenus sur ce
    site Web n’instaure pas une relation médecin-patient entre Santé Ontario et vous. Santé Ontario
    s’engage à garantir des services et communications accessibles aux personnes handicapées. Pour
    recevoir des renseignements relatifs à ce site Web sous un autre format, veuillez communiquer
    avec le service des communications de Santé Ontario au 1-877-280-8538 (ATS : 1-800-855-0511)
    ou par courriel à l’adresse`,
    contactEmail: 'info@ontariohealth.ca',
    privacyPolicyTitle: 'Politique de confidentialité',
    privacyPolicy: 'politique de confidentialité',
    privacyPolicyText,
    termsOfServiceTitle: 'Modalités de service',
    termsOfService: 'modalités de service',
    termsOfServiceText,
  },
  page_titles: {
    sign_in: "Page d'inscription",
    sign_up: 'Page de création de compte',
    home: 'Recherche de ressources',
  },
  common: {
    next: 'Suivant',
    cancel: 'Annuler',
    back: 'Retour',
    save: 'Savegardez',
    continue: 'Continuer',
    previous: 'Précédent',
    success_submit: 'Soumis avec succès',
    failed_submit: 'Echec de la soumission',
    required: 'Ce champ est obligatoire',
  },
  signin: {
    sign_in_title:
      "Êtes-vous un fournisseur de soins pour la maladie de Parkinson's?",
    sign_in_description: 'Inscrivez-vous ici pour être répertorié',
    sign_up_title: 'Besoin de mettre à jour votre liste?',
    sign_up_description: 'Inscrivez-vous ici',
  },
  content: {
    care_finder: {
      where: 'Où recherchez-vous des services?',
      where_placeholder: 'Entrez le code postal',
      distance: 'A quelle distance?',
      search: 'Trouvez votre spécialiste des soins',
      search_results_title: 'Critères de recherche actuels',
      modify: 'Modifier les critères de recherche',
      result_count: '{{resultCount}} correspondances aux critères',
      km_display: '{{value}} km',
      no_results_header:
        "Aucun service n'a été trouvé avec les critères actuels",
      no_results_subheader:
        'Veuillez augmenter la distance de recherche ou élargir la sélection des services.',
      postal_code_error: 'Code postal non trouvé',
      learn_more: 'En savoir plus',
      distance_selected: 'Distance',
      location_selected: 'Localisation',
      not_applicable: 'Non applicable',
    },
  },
  welcome: {
    header: 'Créer un compte',
    subheader:
      "Bienvenue sur le portail dédié à l'enregistrement des kits de test PCR COVID-19 de Santé Ontario.",
    subheader2:
      "Créez un compte pour enregistrer des kits, trouver des lieux de dépôt pratiques et recevoir des notifications avec les résultats. Vous aurez besoin de votre carte verte de santé de l'Ontario pour vous inscrire.",
    create: 'Créé un compte',
    terms_subheader:
      "Veuillez consulter nos conditions d'utilisation pour terminer la création du compte.",
    confirm_terms: "Je confirme que j'ai lu, compris et accepté les  ",
    terms: "Conditions d'utilisation",
  },
  signUp: {
    get_started: 'Commencez',
    tab_1_subheader:
      'Commençons par des informations de base, veuillez préciser votre nom, votre adresse électronique et votre numéro de téléphone',
    tab_4_subheader:
      "Cliquez sur le lien que nous venons d'envoyer à {{ email }} pour confirmer votre adresse e-mail, puis revenez ici...",
    header: 'Créer un compte',
    subheader:
      'Vous devrez entrer les informations de votre CARTE SANITAIRE VERTE pour créer un compte',
    first_name: 'PRÉNOM',
    first_name_placeholder: 'e.g John',
    last_name: 'NOM DE FAMILLE',
    last_name_placeholder: 'e.g Smith',
    phone_number: 'NUMÉRO DE TÉLÉPHONE',
    phone_number_placeholder: 'p.e 123-456-7890',
    sms_opt_in:
      "J'accepte de recevoir des informations par SMS sur mes résultats",
    email: 'COURRIEL',
    email_placeholder: 'p.e mrpotter@gmail.com',
    email_opt_in:
      'Je consens à recevoir des mises à jour par e-mail sur mes résultats',
    birthday: 'DATE DE NAISSANCE',
    birthday_placeholder: 'p.e 24/04/1980',
    postal_code: 'CODE POSTAL',
    postal_code_placeholder: 'p.e K1X 2X6',
    health_card: "CARTE D'ASSURANCE MALADIE À 10 CHIFFRES",
    health_card_placeholder: 'p.e 3948753948',
    version_code: 'CODE DE VERSION À 2 LETTRES',
    version_code_placeholder: 'p.e OH',
    back_code: 'CODE À 9 CARACTÈRES AU DOS DE LA CARTE DE SANTÉ',
    back_code_placeholder: 'p.e 123456789',
    account_created: 'Compte créé',
    account_sub1: 'Bienvenue à Santé Ontario {{name}}!',
    account_sub2:
      "D'ici, vous pouvez soumettre et consulter les résultats des tests COVID-19 et accéder à d'autres services de santé de l'Ontario",
    view: 'Voir le compte',
    register: 'Enregistrer une trousse',
    register_subheader:
      'Vous devez créer un compte pour enregistrer un kit de test CVOID-19.',
    register_subheader2: 'Pour qui enregistrez-vous le kit ?',
    me: 'Moi',
    someone_else: "Quelqu'un d'autre",
    register_button: 'Enregistrer',
    success_header: 'Merci pour votre soumission',
    success_message:
      'Votre soumission a été reçue. Vous recevrez un courriel avec des instructions sur la façon de compléter votre compte. Vous serez informé lorsque votre soumission aura été examinée.',
  },
  barcode: {
    register: 'Enregistrer un kit',
    register_subheader:
      'Localisez le code-barres de votre kit de test COVID-19. Vous pouvez soit le scanner avec votre appareil photo, soit entrer manuellement les 10 chiffres du numéro de série.',
    register_button: 'Enregistrer',
    enter_serial: 'ENTREZ LE NUMÉRO DE SÉRIE DU KIT',
    serial_placeholder: 'p.e 12345678910',
    serial_help: 'NUMÉRO DE SÉRIE',
    scan: 'SCANNER LE BARCODE',
    scan_button: 'Utiliser une caméra',
    difficulties: 'Vous avez des difficultés à scanner?',
    android: 'Instructions pour Android',
    iphone: 'Instructions pour iPhone',
    desktop: "Numérisation à partir d'un ordinateur de bureau/portable",
    registered: 'Kit enregistré!',
    registered_subheader:
      'Tout est fait, maintenant vous devez localiser le centre de santé le plus proche pour déposer le kit.',
    nearest: 'Localiser le centre de santé le plus proche',
    barcodeImageAlt: 'Flèche indiquant le numéro de série',
  },
  not_implemented: 'État "{{connectionState}}" non-implanté',
  search: {
    header: 'Trouver Un Lieu De Dépôt Des Kits',
    subheader:
      'Trouvez un endroit pratique pour déposer un kit COVID-19 pour le test.',
    open_link: 'Ouvrir avec Google Maps',
    content: {
      or: 'OU',
    },
    button: {
      locate_me: 'Localisez-moi',
      go: 'Allez',
      postal_code: 'Code postal',
    },
    error: {
      postal_code: 'Code postal non trouvé',
      geolocation:
        'La géolocalisation est désactivée, veuillez vérifier les paramètres de votre appareil',
    },
  },
};
