import { AppBar, Toolbar, Container } from '@mui/material';

import Logo from './Logo';
import User from './User';

export default function Header(): JSX.Element {
  return (
    <AppBar position="static">
      <Container>
        <Toolbar>
          <Logo />
          <User />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
