import { Stack, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropsWithRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { ReactComponent as EmailLogo } from '../../assets/email.svg';
import TabPanel from '../TabPanel';
import { PATH_SIGNUP_LANDING } from '../../content/constants';

interface ValidationNoticeProps {
  base: string;
  emailStr: string;
}

const ValidationNotice = ({
  base,
  emailStr,
}: ValidationNoticeProps): JSX.Element => {
  const displayHTML = base.replace(
    '{{ email }}',
    `<strong>${emailStr}</strong>`
  );
  return <Typography>{ReactHtmlParser(displayHTML)}</Typography>;
};

export interface GetStartedProps {
  email: string;
  value: number;
  setValue: (arg0: number) => void;
}

export default function GetStarted({
  email,
  value,
  setValue,
}: PropsWithRef<GetStartedProps>): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TabPanel value={value} index={3}>
      <Typography variant="h4" className="search__header" marginBottom={4}>
        {t(`signUp.get_started`)}
      </Typography>
      <ValidationNotice base={t(`signUp.tab_4_subheader`)} emailStr={email} />

      <Stack justifyContent="center" margin={20} flexDirection="row">
        <EmailLogo />
      </Stack>

      <Stack display="flex" flexDirection="row">
        <Button
          onClick={() => setValue(value - 1)}
          className="secondary__button"
          variant="contained"
          color="inherit"
        >
          {t('common.previous')}
        </Button>
        <Button
          className="margin_left"
          onClick={() => navigate(PATH_SIGNUP_LANDING)}
          variant="contained"
          color="primary"
        >
          {t('common.continue')}
        </Button>
      </Stack>
    </TabPanel>
  );
}
