import { createTheme, ThemeOptions } from '@mui/material';
import { theme as baseTheme } from 'ontariohealth-shared-utilities';
import { MuiAppBar } from './appbar';
import { MuiButton } from './button';
import { MuiFormHelperText } from './helperText';
import { MuiFormControl, MuiFormControlLabel } from './formControl';

const updates: ThemeOptions = {
  components: {
    MuiAppBar,
    MuiButton,
    MuiFormControl,
    MuiFormControlLabel,
    MuiFormHelperText,
  },
};

export const userTheme = createTheme(baseTheme, updates);
