import { assertLoggableConnection, } from 'hive-analytics-react';
import _ from 'lodash';
import moment from 'moment';
function createResourceLocation(resourceLocation) {
    const connection = assertLoggableConnection();
    resourceLocation = _.omit(resourceLocation, 'phoneNumbers');
    return connection.bee.api.invoke('createResourceLocation', resourceLocation);
}
function updateResourceLocation(id, props) {
    if (_.isEmpty(props) || !id) {
        return Promise.resolve();
    }
    const connection = assertLoggableConnection();
    props = _.omit(props, 'phoneNumbers');
    return connection.bee.api.invoke('updateResourceLocation', id, props);
}
function deleteResourceLocation(id) {
    if (!id) {
        return Promise.resolve();
    }
    const connection = assertLoggableConnection();
    return connection.bee.api.invoke('deleteResourceLocation', id);
}
function formatDate(date) {
    if (!date) {
        return '';
    }
    return moment(date).format('YYYY');
}
export const LocationService = {
    createResourceLocation,
    updateResourceLocation,
    deleteResourceLocation,
    formatDate,
};
