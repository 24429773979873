import { Box, BoxProps, Divider, styled } from '@mui/material';
import { COLOURS } from 'ontariohealth-shared-utilities';

export const StyledDivider = styled(Divider)({
  backgroundColor: COLOURS.AccentYellow,
  height: '2px',
});

export default function SearchDivider(props: BoxProps): JSX.Element {
  return (
    <Box {...props}>
      <StyledDivider />
    </Box>
  );
}
